import Head from 'next/head';
import React from 'react';

function TheHelmet({ title, description }) {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Head>
    </>
  );
}

export default TheHelmet;
