import dynamic from 'next/dynamic';
import config from '../config';
import TheHelmet from '../components/TheHelmet';

const PageHome = dynamic(() => import('../components/PageHome'), {
  ssr: false,
});

export default function Home() {
  return (
    <div>
      <TheHelmet title={config.title} description={config.description} />
      <PageHome />
    </div>
  );
}
